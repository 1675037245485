<template>
    <v-container fluid>
        <v-form @submit.prevent="saveTracking">
            <ValidationObserver
                ref="observer"
                v-slot="{ invalid, validated, passes, validate }"
            >
                <v-card flat light>
                    <v-card-text>
                        <v-row>
                            <v-col class="py-0" cols="12" md="6">
                                <h3 class="headline pb-4">{{ heading }}</h3>
                            </v-col>
                            <v-col class="py-0" cols="12" md="6">
                                <v-tabs
                                    v-model="tab"
                                    background-color="transparent"
                                    :right="$vuetify.breakpoint.mdAndUp"
                                    :grow="$vuetify.breakpoint.smAndDown"
                                    color="deep-purple accent-4"
                                    @change="setLanguage"
                                    class="pb-4"
                                >
                                    <v-tab
                                        ref="language"
                                        v-for="language in languages"
                                        :key="language"
                                    >
                                        <span
                                            :class="
                                                exist_translations[language]
                                                    ? ''
                                                    : 'red--text '
                                            "
                                            >{{ language }}</span
                                        >
                                    </v-tab>
                                </v-tabs>
                            </v-col>

                            <v-col class="py-0" cols="12" sm="6">
                                <ValidationProvider
                                    ref="client"
                                    rules="required"
                                    v-slot="{ errors, valid }"
                                >
                                    <v-autocomplete
                                        v-model="client"
                                        :items="clientItems"
                                        :error="!valid"
                                        :error-messages="errors"
                                        :search-input.sync="clientSearching"
                                        item-text="name"
                                        item-value="id"
                                        prepend-icon="mdi-account-supervisor"
                                        :loading="loadingClients"
                                        :disabled="loading"
                                        @click="clearClients"
                                        :no-data-text="
                                            clientSearching
                                                ? $t('nothing_found_by', {
                                                      search: clientSearching,
                                                  })
                                                : $t(
                                                      'nothing_found_client_name'
                                                  )
                                        "
                                        :label="$t('client')"
                                        @click:clear="countryItems = []"
                                        color="primary"
                                        return-object
                                        clearable
                                        autocomplete="off"
                                    >
                                    </v-autocomplete>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="6">
                                <ValidationProvider
                                    ref="tracking_name"
                                    name="tracking_name"
                                    rules="required|min:1|max:255"
                                    v-slot="{ errors, valid }"
                                >
                                    <v-text-field
                                        v-model="tracking_name"
                                        type="text"
                                        :error="!valid"
                                        :error-messages="errors"
                                        :disabled="loading"
                                        prepend-icon="mdi-arrow-decision"
                                        :label="$t('tracking_name')"
                                        color="primary"
                                        clearable
                                    >
                                    </v-text-field>
                                </ValidationProvider>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-progress-linear
                            v-model="progress"
                            :active="loading"
                            class="mx-2"
                        ></v-progress-linear>
                        <v-spacer></v-spacer>
                        <v-btn
                            type="submit"
                            :disabled="invalid || loading"
                            :loading="loading"
                            color="primary"
                        >
                            {{ $t("save") }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </ValidationObserver>
        </v-form>
    </v-container>
</template>
<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { mapGetters } from "vuex";
import debounce from "lodash/debounce";

export default {
    name: "TrackingForm",
    components: {
        ValidationProvider,
        ValidationObserver,
    },
    inject: ["forceRerender"],
    data() {
        return {
            heading: null,
            progress: 0,
            tab: 0,
            language: null,
            loading: false,
            errors: {},
            tracking_name: null,
            exist_translations: {},
            all_translations: true,
            client: null,
            clientItems: [],
            loadingClients: false,
            clientSearching: null,
        };
    },
    computed: {
        ...mapGetters(["listLanguages", "lang"]),
        languages() {
            return this.listLanguages;
        },
    },
    watch: {
        clientSearching: debounce(function (val) {
            if (val && !this.client) {
                this.getClients(val);
            }
        }, 500),
    },
    mounted() {
        this.language = this.languages[this.tab];
        this.checkCreate();
    },
    methods: {
        checkCreate() {
            if (this.$route.name === "tracking.create") {
                this.heading = this.$t("tracking_creation");
            } else {
                this.heading = this.$t("tracking_editing");
                if (this.$route.params.id) {
                    this.getTracking();
                }
            }
        },
        setLanguage(val) {
            this.language = this.languages[val];
            this.checkCreate();
        },
        clearClients() {
            if (!this.client) {
                this.clientItems = [];
            }
        },
        async getClients(str) {
            if (str) {
                this.loadingClients = true;
                let params = {};
                params.filter = "search";
                if (str !== "undefined") {
                    params.name = str;
                }
                await this.$http
                    .get("admin/client", {
                        params: params,
                    })
                    .then((res) => {
                        this.clientItems = res.body.data;
                    })
                    .catch((err) => {
                        this.clientItems = [];
                        this.$toastr.error(
                            this.$t("failed_to_get_list_clients")
                        );
                    })
                    .finally((end) => {
                        this.loadingClients = false;
                    });
            }
        },
        async getTracking() {
            var _this = this;
            this.progress = 0;
            this.loading = true;
            let params = {};
            if (this.language) {
                params.language = this.language;
            }
            await this.$http
                .get(`admin/tracking/${this.$route.params.id}`, {
                    params: params,
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round(
                                (e.loaded / e.total) * 100
                            );
                        }
                    },
                })
                .then((res) => {
                    this.tracking_name = res.body.data.name;
                    let client = res.body.data.client;
                    if (client && client.id) {
                        this.client = client.id;
                        this.clientItems = [res.body.data.client];
                    } else {
                        this.clientItems = [];
                    }

                    this.exist_translations = res.body.data.exist_translations;
                    this.all_translations = res.body.data.all_translations;
                })
                .catch((err) => {
                    this.$toastr.error(this.$t("failed_to_get_tracking"));
                })
                .finally((end) => {
                    this.progress = 0;
                    this.loading = false;
                });
        },
        async saveTracking() {
            var _this = this;
            this.progress = 0;
            this.loading = true;
            var formData = new FormData();
            if (this.language) {
                formData.append("language", this.language);
            }
            if (this.tracking_name) {
                formData.append("tracking_name", this.tracking_name);
            }

            if (this.client) {
                if (this.client.id) {
                    formData.append("client_id", this.client.id);
                } else {
                    formData.append("client_id", this.client);
                }
            }

            if (this.$route.params.id) {
                // Save
                await this.$http
                    .put(`admin/tracking/${this.$route.params.id}`, formData, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round(
                                    (e.loaded / e.total) * 100
                                );
                            }
                        },
                    })
                    .then((res) => {
                        this.$toastr.success(
                            this.$t("tracking_has_been_updated")
                        );
                        if (
                            res &&
                            res.body &&
                            res.body.data &&
                            res.body.data.exist_translations
                        ) {
                            this.exist_translations =
                                res.body.data.exist_translations;
                            this.all_translations =
                                res.body.data.all_translations;
                        }
                    })
                    .catch((err) => {
                        this.$toastr.error(
                            this.$t("tracking_has_not_been_updated")
                        );
                        if (err.body.message) {
                            for (let prop in err.body.errors) {
                                if (
                                    hasOwnProperty.call(err.body.errors, prop)
                                ) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0],
                                        ]);
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message);
                            }
                        }
                    })
                    .finally((end) => {
                        this.progress = 0;
                        this.loading = false;
                    });
            } else {
                // Add
                await this.$http
                    .post("admin/tracking", formData, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round(
                                    (e.loaded / e.total) * 100
                                );
                            }
                        },
                    })
                    .then((res) => {
                        this.$toastr.success(
                            this.$t("tracking_has_been_added")
                        );
                        if (
                            res &&
                            res.body &&
                            res.body.data &&
                            res.body.data.id
                        ) {
                            this.exist_translations =
                                res.body.data.exist_translations;
                            this.all_translations =
                                res.body.data.all_translations;
                            this.$router.push({
                                name: "tracking.edit",
                                params: {
                                    id: res.body.data.id,
                                },
                            });
                            //this.forceRerender()
                        }
                    })
                    .catch((err) => {
                        this.$toastr.error(
                            this.$t("tracking_has_not_been_added")
                        );
                        if (err.body.message) {
                            for (let prop in err.body.errors) {
                                if (
                                    hasOwnProperty.call(err.body.errors, prop)
                                ) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0],
                                        ]);
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message);
                            }
                        }
                    })
                    .finally((end) => {
                        this.progress = 0;
                        this.loading = false;
                    });
            }
        },
    },
};
</script>
<style scoped></style>
