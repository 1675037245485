var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.saveTracking.apply(null, arguments)}}},[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function({ invalid, validated, passes, validate }){return [_c('v-card',{attrs:{"flat":"","light":""}},[_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('h3',{staticClass:"headline pb-4"},[_vm._v(_vm._s(_vm.heading))])]),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('v-tabs',{staticClass:"pb-4",attrs:{"background-color":"transparent","right":_vm.$vuetify.breakpoint.mdAndUp,"grow":_vm.$vuetify.breakpoint.smAndDown,"color":"deep-purple accent-4"},on:{"change":_vm.setLanguage},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.languages),function(language){return _c('v-tab',{key:language,ref:"language",refInFor:true},[_c('span',{class:_vm.exist_translations[language]
                                                ? ''
                                                : 'red--text '},[_vm._v(_vm._s(language))])])}),1)],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"6"}},[_c('ValidationProvider',{ref:"client",attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors, valid }){return [_c('v-autocomplete',{attrs:{"items":_vm.clientItems,"error":!valid,"error-messages":errors,"search-input":_vm.clientSearching,"item-text":"name","item-value":"id","prepend-icon":"mdi-account-supervisor","loading":_vm.loadingClients,"disabled":_vm.loading,"no-data-text":_vm.clientSearching
                                            ? _vm.$t('nothing_found_by', {
                                                  search: _vm.clientSearching,
                                              })
                                            : _vm.$t(
                                                  'nothing_found_client_name'
                                              ),"label":_vm.$t('client'),"color":"primary","return-object":"","clearable":"","autocomplete":"off"},on:{"update:searchInput":function($event){_vm.clientSearching=$event},"update:search-input":function($event){_vm.clientSearching=$event},"click":_vm.clearClients,"click:clear":function($event){_vm.countryItems = []}},model:{value:(_vm.client),callback:function ($$v) {_vm.client=$$v},expression:"client"}})]}}],null,true)})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"6"}},[_c('ValidationProvider',{ref:"tracking_name",attrs:{"name":"tracking_name","rules":"required|min:1|max:255"},scopedSlots:_vm._u([{key:"default",fn:function({ errors, valid }){return [_c('v-text-field',{attrs:{"type":"text","error":!valid,"error-messages":errors,"disabled":_vm.loading,"prepend-icon":"mdi-arrow-decision","label":_vm.$t('tracking_name'),"color":"primary","clearable":""},model:{value:(_vm.tracking_name),callback:function ($$v) {_vm.tracking_name=$$v},expression:"tracking_name"}})]}}],null,true)})],1)],1)],1),_c('v-card-actions',[_c('v-progress-linear',{staticClass:"mx-2",attrs:{"active":_vm.loading},model:{value:(_vm.progress),callback:function ($$v) {_vm.progress=$$v},expression:"progress"}}),_c('v-spacer'),_c('v-btn',{attrs:{"type":"submit","disabled":invalid || _vm.loading,"loading":_vm.loading,"color":"primary"}},[_vm._v(" "+_vm._s(_vm.$t("save"))+" ")])],1)],1)]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }